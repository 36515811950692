import { debounce } from 'lodash'
import { useState } from 'react'

import { DataTable } from '@/components/ui/data-table'
import { useInstalledBlueprintManifestsTable } from '@/hooks/useInstalledBlueprintManifestsTable'

export const BlueprintManifestsList = () => {
  const [search, _setSearch] = useState<string>('')

  const setSearch = debounce((search) => {
    _setSearch(search)
  }, 300)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })

  const { columns, data, totalPages, isLoading } =
    useInstalledBlueprintManifestsTable({
      search,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize
    })

  return (
    <>
      <DataTable
        columns={columns}
        data={data || []}
        defaultGlobalFilter={search}
        isLoading={isLoading}
        manualPagination={{
          pagination,
          setPagination,
          pageCount: totalPages
        }}
        setDefaultGlobalFilter={setSearch}
        tableConfigName="installed-blueprint-manifests"
        toolbarProps={{
          exportButtonProps: {
            loading: isLoading
          }
        }}
      />
    </>
  )
}
