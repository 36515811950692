import { PermissionComponents } from '@/api/permissions-client'
import { PricingTierComponents } from '@/api/pricing-tier-client'

export const isValidJSON = (json: string) => {
  try {
    JSON.parse(json)

    return true
  } catch (e) {
    return false
  }
}

export const unsensitiveFilter = (row: any, id: string, value: string[]) => {
  try {
    const rowValue = row.getValue(id)!.toString()?.toLowerCase()
    const items = rowValue.split(',')

    return value.some((v) =>
      items.some((i: string) => i?.toLowerCase() === v?.toLowerCase())
    )
  } catch (error) {
    return false
  }
}

export const getGrantsForPricingTier = (
  pricingTier: PricingTierComponents.Schemas.PricingTier,
  isPartner?: boolean
) => {
  const grants: PermissionComponents.Schemas.Grant[] = []

  /**
   * Partner organizations have their access defined by their sharing roles
   * with the organization, so their root roles don't define Entity specific
   * access
   */
  if (!isPartner) {
    // all vendor organizations have access to entities
    grants.push({
      action: 'entity:*'
    })

    // access to schema via entity builder
    grants.push({
      action: 'schema:*'
    })

    // user management
    grants.push({
      action: 'user:*'
    })

    // role management
    grants.push({
      action: 'role:*'
    })

    // organization management
    grants.push({
      action: 'organization:*'
    })

    // access to messaging
    grants.push({
      action: 'message:*'
    })

    // workflow builder
    grants.push({
      action: 'workflow:definition:*'
    })

    // workflow execution
    grants.push({
      action: 'workflow:execution:*'
    })

    // 360 dashboard
    grants.push({
      action: 'dashboard:*'
    })

    // access to design builder
    grants.push({
      action: 'design:*'
    })

    // access to automation
    grants.push({
      action: 'automation:*'
    })

    // access to tokens
    grants.push({
      action: 'token:*'
    })

    // access to blueprints
    grants.push({
      action: 'blueprint:*'
    })

    // notes
    grants.push({
      action: 'note:*'
    })

    // email settings
    grants.push({
      action: 'email_setting:*'
    })

    // epilot email address
    grants.push({
      action: 'epilot_email_address:*'
    })

    // meter readings
    grants.push({
      action: 'meter_reading:*'
    })
  }

  grants.push(...convertSettingsToGrants(pricingTier))

  return grants
}

const convertSettingsToGrants = (
  pricingTier: PricingTierComponents.Schemas.PricingTier
) => {
  if (!pricingTier) {
    return []
  }

  const grants: PermissionComponents.Schemas.Grant[] = []

  const settings = {
    ...(pricingTier.settings || {}),
    ...(pricingTier.override_settings || {})
  }

  Object.entries(settings).forEach(
    ([key, value]: [key: string, value: { enabled: boolean }]) => {
      switch (key) {
        case 'bulk_messages':
          grants.push({
            action: 'message:bulk_send',
            effect: value?.enabled ? 'allow' : 'deny'
          })
          break
        default:
          // only process boolean value
          if (value?.enabled === true) {
            const action = `${key}:*`

            grants.push({ action })
          }
      }
    }
  )

  return grants
}
