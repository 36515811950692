import { Components } from '@epilot/blueprint-manifest-client'

import { BlueprintManifestTableColumns } from '@/modules/blueprints/blueprint-manifests/columns'
import { useQueryListAllInstalledBlueprintManifests } from '@/services/blueprint-manifest.service'

export const useInstalledBlueprintManifestsTable = (params: {
  search: string
  limit: number
  offset: number
}) => {
  const { data, isFetching } =
    useQueryListAllInstalledBlueprintManifests(params)

  return {
    data: (data?.results?.map((manifest) => manifest) ||
      []) as Components.Schemas.ManifestItem[],
    page: data?.page,
    totalPages: data?.total_pages,
    columns: BlueprintManifestTableColumns,
    isLoading: isFetching
  }
}
