import { useState } from 'react'

import { DataTable } from '@/components/ui/data-table'
import { useAppSandboxRequestsTable } from '@/hooks/useAppSandboxRequestsTable'

export const AppsSandboxRequestsList = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })

  const { columns, data, totalPages, isLoading } = useAppSandboxRequestsTable({
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize
  })

  return (
    <>
      <DataTable
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        manualPagination={{
          pagination,
          setPagination,
          pageCount: totalPages
        }}
        tableConfigName="app-sandbox-requests"
        toolbarProps={{
          exportButtonProps: {
            loading: isLoading
          }
        }}
      />
    </>
  )
}
