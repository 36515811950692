import { useNavigate } from 'react-router-dom'

import { DataTable } from '@/components/ui/data-table'
import { useMarketplaceBlueprintsTable } from '@/hooks'
import { RoutePaths } from '@/routes/routes'

const DEFAULT_COLUMNS_TO_HIDE = {}

export const MarketplaceBlueprintsList = () => {
  const { columns, data, isLoading } = useMarketplaceBlueprintsTable()

  const navigate = useNavigate()

  return (
    <>
      <DataTable
        allowCreate
        columns={columns}
        data={data || []}
        defaultColumnVisibility={DEFAULT_COLUMNS_TO_HIDE}
        isLoading={isLoading}
        onRowClick={(row) => {
          navigate(`${RoutePaths.MARKETPLACE_BLUEPRINTS}/${row.original.id}`)
        }}
        tableConfigName="marketplace-blueprints"
        toolbarProps={{
          exportButtonProps: {
            loading: isLoading
          }
        }}
      />
    </>
  )
}
