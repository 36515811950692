import { Components } from '@epilot/blueprint-manifest-client'
import { useQuery } from '@tanstack/react-query'

import { ErrorResponse } from '../models'

import { config, getToken } from '@/api'

const BLUEPRINT_MANIFEST_QUERY_IDS = {
  LIST_ALL_INSTALLED_MANIFESTS: 'LIST_ALL_INSTALLED_MANIFESTS'
}

export const useQueryListAllInstalledBlueprintManifests = ({
  search,
  limit,
  offset
}: {
  search: string
  limit: number
  offset: number
}) => {
  return useQuery<{
    total: number
    page: number
    total_pages: number
    results: Components.Schemas.ManifestItem[]
  }>({
    queryKey: [
      BLUEPRINT_MANIFEST_QUERY_IDS.LIST_ALL_INSTALLED_MANIFESTS,
      search,
      limit,
      offset
    ],
    queryFn: async () => {
      const token = await getToken()

      const queryParams = new URLSearchParams()

      if (search) queryParams.append('name', search)
      if (limit) queryParams.append('limit', String(limit))
      if (offset) queryParams.append('offset', String(offset))

      // Build the final URL with query parameters
      const url = `${
        config.BLUEPRINT_MANIFEST
      }/v1/blueprint-manifest/manifests:all?${queryParams.toString()}`

      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        const error: ErrorResponse = await res.json()

        throw new Error(error.message)
      }

      const data = await res.json()

      return data
    },
    refetchOnWindowFocus: true
  })
}
