import { SandboxPipelineTableColumns } from '@/modules/blueprints/sandbox-requests/columns'
import { useQueryListAllPipelines } from '@/services/sandbox.service'

export const useSandboxPipelinesTable = (params: {
  limit: number
  offset: number
}) => {
  const { data, isFetching } = useQueryListAllPipelines(params)

  return {
    data: data?.results?.map((pipeline) => pipeline) || [],
    page: data?.page,
    totalPages: data?.total_pages,
    columns: SandboxPipelineTableColumns,
    isLoading: isFetching
  }
}
