import { useState } from 'react'

import { DataTable } from '@/components/ui/data-table'
import { useSandboxPipelinesTable } from '@/hooks/useSandboxRequestsTable'

export const SandboxRequestsList = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })

  const { columns, data, totalPages, isLoading } = useSandboxPipelinesTable({
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize
  })

  return (
    <>
      <DataTable
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        manualPagination={{
          pagination,
          setPagination,
          pageCount: totalPages
        }}
        tableConfigName="sandbox-requests"
        toolbarProps={{
          exportButtonProps: {
            loading: isLoading
          }
        }}
      />
    </>
  )
}
