import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'

const TABS = [
  {
    key: 'marketplace-blueprints',
    label: 'Marketplace Blueprints',
    path: '/blueprints/marketplace-blueprints'
  },
  {
    key: 'marketplace-blueprint-resources',
    label: 'Marketplace Blueprint Resources',
    path: '/blueprints/marketplace-blueprint-resources'
  },
  {
    key: 'installed-blueprint-manifests',
    label: 'Installed Blueprint Manifests',
    path: '/blueprints/blueprint-manifests'
  },
  {
    key: 'sandbox-requests',
    label: 'Sandbox Requests',
    path: '/blueprints/sandbox-requests'
  }
]

export const BlueprintsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const activeTab = TABS.find((tab) =>
    location.pathname.startsWith(tab.path)
  )?.key

  return (
    <div className="w-full h-full flex flex-col">
      <Tabs
        className="w-full flex flex-col"
        defaultValue={
          TABS.find((tab) => location.pathname.startsWith(tab.path))?.key ??
          TABS[0].key
        }
        onValueChange={(key) => {
          const tab = TABS.find((t) => t.key === key)

          if (tab) navigate(tab.path)
        }}
        value={activeTab || TABS[0].key}
      >
        <TabsList className="grid w-full grid-cols-4">
          {TABS.map(({ key, label }) => (
            <TabsTrigger key={key} value={key}>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <div className="w-full h-full mt-4">
        <Outlet />
      </div>
    </div>
  )
}
