import { ChevronDownIcon } from 'lucide-react'

import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { TableColumn } from '@/models'
import { PipelineItem } from '@/services/sandbox.service'
import { unsensitiveFilter } from '@/utils'

export const SandboxPipelineTableColumns: TableColumn<PipelineItem>[] = [
  {
    id: 'select',
    size: 30,
    header: ({ table }) => (
      <div className="flex items-center space-x-2">
        <Checkbox
          aria-label="Select all in the page"
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="cursor-pointer">
              <ChevronDownIcon className="w-4 h-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex items-center w-56">
            <Checkbox
              aria-label="Select all"
              checked={table.getIsAllRowsSelected()}
              className="mr-2"
              id="select-all"
              onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
            />
            <span>Select all items</span>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        aria-label="Select row"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
      />
    ),
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'pipeline_id',
    accessorKey: 'pipeline_id',
    displayName: 'ID',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    enableResizing: false,
    filterFn: unsensitiveFilter,
    sortingFn: (a, b) => {
      const idA = a.original.pipeline_id
      const idB = b.original.pipeline_id

      if (!idA && !idB) {
        return 0
      } else if (!idA) {
        return 1
      } else if (!idB) {
        return -1
      }

      return parseInt(idA) - parseInt(idB)
    }
  },
  {
    id: 'sandbox_org_id',
    accessorKey: 'sandbox_org_id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sandbox Org ID" />
    ),
    displayName: 'Sandbox Org ID',
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'sandbox_org_name',
    accessorKey: 'sandbox_org_name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sandbox Org Name" />
    ),
    displayName: 'Sandbox Org Name',
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'production_org_id',
    accessorKey: 'production_org_id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Prod Org ID" />
    ),
    displayName: 'Prod Org ID',
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'production_org_name',
    accessorKey: 'production_org_name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Prod Org Name" />
    ),
    displayName: 'Prod Org Name',
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Creation Date" />
    ),
    accessorFn: (row) => (row.created_at ? row.created_at : undefined),
    cell: ({ row }) => {
      if (!row.original.created_at) {
        return ''
      }

      const date = new Date(row.original.created_at)

      return date.toLocaleString()
    },
    sortingFn: (a, b) => {
      if (!a.original.created_at && !b.original.created_at) {
        return 0
      }

      if (!a.original.created_at) {
        return 1
      }

      if (!b.original.created_at) {
        return -1
      }

      const dateA = new Date(a.original.created_at)
      const dateB = new Date(b.original.created_at)

      if (dateA === null && dateB === null) {
        return 0
      } else if (dateA === null) {
        return 1
      } else if (dateB === null) {
        return -1
      }

      return dateA.getTime() - dateB.getTime()
    },
    displayName: 'Creation Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  }
]
