export const LOGIN_AS_TOKEN_COOKIE_NAME = 'login_as_token'
export const COOKIE_MAX_AGE_SECONDS = 300 // 5 minutes

export interface CookieOptions {
  domain: string
  path: string
  secure: boolean
  sameSite: 'Strict' | 'Lax' | 'None'
  maxAge: number
}

const getDomainFromHostname = ({
  hostname,
  includeSubdomains = true
}: {
  hostname: string
  includeSubdomains?: boolean
}): string => {
  const parts = hostname.split('.')

  const domain = parts.slice(-2).join('.')

  return includeSubdomains ? `.${domain}` : domain
}

export const generateLoginAsTokenCookie = (token: string): string => {
  const cookieOptions: CookieOptions = {
    domain: getDomainFromHostname({
      hostname: window.location.hostname
    }),
    path: '/',
    secure: true,
    sameSite: 'Strict',
    maxAge: COOKIE_MAX_AGE_SECONDS
  }

  const cookieString = [
    `${LOGIN_AS_TOKEN_COOKIE_NAME}=${token}`,
    `Domain=${cookieOptions.domain}`,
    `Path=${cookieOptions.path}`,
    cookieOptions.secure ? 'Secure' : '',
    `SameSite=${cookieOptions.sameSite}`,
    `max-age=${cookieOptions.maxAge}`
  ].join('; ')

  return cookieString
}
