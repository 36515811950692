import { useEffect, useState } from 'react'

import { IMarketplaceBlueprintResource } from '../models'

import { MarketplaceBlueprintResourcesTableColumns } from '@/modules/blueprints/marketplace/blueprint-resources/columns'
import {
  useQueryGetMarketplaceBlueprintResources,
  useQueryGetMarketplaceBlueprints
} from '@/services/marketplace-cms.service'

export const useMarketplaceBlueprintResourcesTable = () => {
  const { data, isFetching } = useQueryGetMarketplaceBlueprintResources()
  const { data: blueprints, isFetching: isFetchingBlueprints } =
    useQueryGetMarketplaceBlueprints()
  const [resources, setResources] = useState<IMarketplaceBlueprintResource[]>(
    []
  )

  useEffect(() => {
    if (
      data?.marketplace_blueprint_resources &&
      blueprints?.marketplace_blueprints
    ) {
      setResources(
        data.marketplace_blueprint_resources.map((resource) => {
          const connectedBlueprints =
            blueprints?.marketplace_blueprints
              ?.filter((b) => b.resources?.includes(resource.id as string))
              .map((b) => b.name) ?? []

          return {
            ...resource,
            connected_blueprints: connectedBlueprints
          }
        })
      )
    }
  }, [
    data?.marketplace_blueprint_resources,
    blueprints?.marketplace_blueprints
  ])

  return {
    data: resources,
    columns: MarketplaceBlueprintResourcesTableColumns,
    isLoading: isFetching || isFetchingBlueprints
  }
}
