import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Icons } from '@/components/ui/icons'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { MultiSelectAutoComplete } from '@/components/ui/multi-select-autocomplete'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import {
  ICreateMarketplaceBlueprintResource,
  IMarketplaceBlueprint,
  IMarketplaceBlueprintResource
} from '@/models'
import { RoutePaths } from '@/routes/routes'
import {
  useMutationCreateMarketplaceBlueprintResource,
  useMutationUpdateMarketplaceBlueprint,
  useMutationUpdateMarketplaceBlueprintResource,
  useQueryGetMarketplaceBlueprintResource,
  useQueryGetMarketplaceBlueprintResourceTypes,
  useQueryGetMarketplaceBlueprints
} from '@/services/marketplace-cms.service'

export function BlueprintResourceDetail() {
  const { resourceId } = useParams<{ resourceId: string }>()
  const navigate = useNavigate()

  const { data: blueprintResource, isLoading } =
    useQueryGetMarketplaceBlueprintResource(resourceId as string, {
      enabled: !!resourceId
    })
  const { data: blueprints, isLoading: isLoadingBlueprints } =
    useQueryGetMarketplaceBlueprints({
      enabled: !!resourceId
    })
  const { data: types, isLoading: isLoadingTypes } =
    useQueryGetMarketplaceBlueprintResourceTypes()
  const [formData, setFormData] = useState<
    Partial<IMarketplaceBlueprintResource>
  >({
    type: '',
    description: '',
    name: '',
    order: 0
  })
  const [connectedBlueprints, setConnectedBlueprints] = useState<
    IMarketplaceBlueprint[] | undefined
  >()
  const [isMutating, setIsMutating] = useState(false)
  const [isUpdatingBlueprints, setIsUpdatingBlueprints] = useState(false)

  const createResourceMutation = useMutationCreateMarketplaceBlueprintResource()

  const updateResourceMutation = useMutationUpdateMarketplaceBlueprintResource()
  const updateBlueprintMutation = useMutationUpdateMarketplaceBlueprint()

  useEffect(() => {
    if (blueprintResource && blueprints) {
      setConnectedBlueprints(
        blueprints.marketplace_blueprints?.filter((b) =>
          b.resources?.includes(blueprintResource.id as string)
        ) || []
      )
    }

    if (blueprintResource) {
      setFormData((prevData) => ({
        ...prevData,
        ...blueprintResource
      }))
    }
  }, [blueprintResource, blueprints])

  const handleAddBlueprint = (blueprint: IMarketplaceBlueprint) => {
    setIsUpdatingBlueprints(true)

    updateBlueprintMutation.mutate(
      {
        ...blueprint,
        resources: [
          ...(blueprint.resources || []),
          blueprintResource?.id
        ] as string[]
      },
      {
        onSuccess: () => {
          toast({
            title: 'Blueprint added successfully'
          })

          setConnectedBlueprints((prevData) => [...(prevData || []), blueprint])
        },
        onError: (err) => {
          toast({
            title: 'Error',
            description: err?.message || 'An error occurred'
          })
        },
        onSettled: () => {
          setIsUpdatingBlueprints(false)
        }
      }
    )
  }

  const handleRemoveBlueprint = (blueprint: IMarketplaceBlueprint) => {
    setIsUpdatingBlueprints(true)

    updateBlueprintMutation.mutate(
      {
        ...blueprint,
        resources: blueprint.resources?.filter(
          (r) => r !== blueprintResource?.id
        ) as string[]
      },
      {
        onSuccess: () => {
          toast({
            title: 'Blueprint removed successfully'
          })

          setConnectedBlueprints((prevData) =>
            prevData?.filter((b) => b.id !== blueprint.id)
          )
        },
        onError: (err) => {
          toast({
            title: 'Error',
            description: err?.message || 'An error occurred'
          })
        },
        onSettled: () => {
          setIsUpdatingBlueprints(false)
        }
      }
    )
  }

  const handleUpdateResource = async () => {
    setIsMutating(true)

    updateResourceMutation.mutate(
      {
        ...formData,
        id: resourceId
      } as IMarketplaceBlueprintResource,
      {
        onSuccess: () => {
          toast({
            title: 'Resource updated successfully'
          })
        },
        onError: (err) => {
          toast({
            title: 'Error',
            description: err?.message || 'An error occurred'
          })
        },
        onSettled: () => {
          setIsMutating(false)
        }
      }
    )
  }

  const handleCreateResource = async () => {
    setIsMutating(true)

    createResourceMutation.mutate(
      {
        ...formData
      } as ICreateMarketplaceBlueprintResource,
      {
        onSuccess: (data) => {
          toast({
            title: 'Resource created successfully'
          })

          navigate(`${RoutePaths.MARKETPLACE_BLUEPRINT_RESOURCES}/${data.id}`)
        },
        onError: (err) => {
          toast({
            title: 'Error',
            description: err?.message || 'An error occurred'
          })
        },
        onSettled: () => {
          setIsMutating(false)
        }
      }
    )
  }

  return (
    <Tabs className="w-full" defaultValue="settings">
      <TabsList className="grid w-full grid-cols-1">
        <TabsTrigger loading={isLoading} value="settings">
          Settings
        </TabsTrigger>
      </TabsList>
      <TabsContent value="settings">
        <div className="space-y-4">
          <div className="flex space-x-12">
            <div className="w-1/2 space-y-4">
              {resourceId && (
                <div className="space-y-2">
                  <Label htmlFor="id">ID</Label>
                  <Input disabled id="id" value={resourceId} />
                </div>
              )}
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      name: e.target.value
                    }))
                  }}
                  value={formData?.name ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="order">Order</Label>
                <Input
                  id="order"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      order: e.target.value ? parseInt(e.target.value) : ''
                    }))
                  }}
                  type="number"
                  value={formData?.order ?? 0}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  name="description"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      description: e.target.value
                    }))
                  }}
                  value={formData?.description ?? ''}
                />
              </div>
              {resourceId && (
                <>
                  <div className="space-y-2">
                    <Label htmlFor="created_at">Created At</Label>
                    <Input
                      disabled
                      id="created_at"
                      value={
                        blueprintResource?.created_at
                          ? new Date(
                              blueprintResource?.created_at
                            )?.toLocaleString()
                          : ''
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="updated_at">Updated At</Label>
                    <Input
                      disabled
                      id="updated_at"
                      value={
                        blueprintResource?.updated_at
                          ? new Date(
                              blueprintResource?.updated_at
                            )?.toLocaleString()
                          : ''
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="w-1/2 space-y-4">
              {resourceId && (
                <div className="space-y-2">
                  <Label>Connected Blueprints</Label>
                  <MultiSelectAutoComplete
                    disabled={isLoadingBlueprints || isUpdatingBlueprints}
                    isLoading={isLoadingBlueprints || isUpdatingBlueprints}
                    items={blueprints?.marketplace_blueprints || []}
                    labelKey="name"
                    onSelect={(item) =>
                      handleAddBlueprint(item as IMarketplaceBlueprint)
                    }
                    onUnselect={(item) =>
                      handleRemoveBlueprint(item as IMarketplaceBlueprint)
                    }
                    placeholder="Connect the blueprints to this resource"
                    selectedItems={connectedBlueprints || []}
                    valueKey="id"
                  />
                </div>
              )}
              <div className="space-y-2">
                <Label htmlFor="type">Type</Label>
                <Select
                  disabled={isLoadingTypes}
                  name="type"
                  onValueChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      type: e
                    }))
                  }}
                  value={formData?.type ?? ''}
                >
                  <SelectTrigger
                    className={classNames({
                      'text-primary': formData?.type,
                      'text-placeholder': !formData?.type
                    })}
                  >
                    {isLoadingTypes ? (
                      <Icons.spinner className="w-4 h-4 m-0" />
                    ) : (
                      <SelectValue placeholder="Select a type" />
                    )}
                  </SelectTrigger>
                  <SelectContent>
                    {types?.marketplace_blueprint_resource_types?.map(
                      (type) => (
                        <SelectItem key={type.id} value={type.id as string}>
                          {type.name}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </div>
              {resourceId && (
                <>
                  <div className="space-y-2">
                    <Label htmlFor="is_draft">Is Draft</Label>
                    <Input
                      disabled
                      id="is_draft"
                      name="is_draft"
                      type="text"
                      value={blueprintResource?.is_draft ? 'True' : 'False'}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="is_archived">Is Archived</Label>
                    <Input
                      disabled
                      id="is_archived"
                      name="is_archived"
                      type="text"
                      value={blueprintResource?.is_archived ? 'True' : 'False'}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <Button
              loading={isMutating}
              onClick={resourceId ? handleUpdateResource : handleCreateResource}
              type="submit"
            >
              {resourceId ? 'Update Resource' : 'Create Resource'}
            </Button>
          </div>
        </div>
      </TabsContent>
    </Tabs>
  )
}
