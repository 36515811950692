import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

const TABS = {
  'genai-reports': {
    label: 'GenAI Reports',
    value: 'genai-reports',
    embedUrl:
      'https://app.powerbi.com/reportEmbed?reportId=a1374679-8669-4430-b9df-96b4b06c6ce4&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88'
  },
  'usage-metrics': {
    label: 'Usage Metrics',
    value: 'usage-metrics',
    embedUrl:
      'https://app.powerbi.com/reportEmbed?reportId=a15f97e7-56c7-41f6-866e-5c85fff9ecaa&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88'
  },
  blueprints: {
    label: 'Blueprints',
    value: 'blueprints',
    embedUrl:
      'https://app.powerbi.com/reportEmbed?reportId=93b6f44a-3944-4300-a730-06e7127dea8c&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88'
  }
}

const InternalBI = () => {
  return (
    <Tabs
      className="w-full h-full flex flex-col"
      defaultValue="individual-reports"
    >
      <TabsList className={`grid w-full grid-cols-${Object.keys(TABS).length}`}>
        {Object.entries(TABS).map(([key, value]) => (
          <TabsTrigger key={key} value={key}>
            {value.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {Object.entries(TABS).map(([key, value]) => (
        <TabsContent className="h-full" key={key} value={key}>
          <iframe
            allowFullScreen={true}
            height={'100%'}
            src={value.embedUrl}
            title="epilot Internal BI"
            width={'100%'}
          />
        </TabsContent>
      ))}
    </Tabs>
  )
}

export default InternalBI
