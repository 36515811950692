import { useNavigate } from 'react-router-dom'

import { DataTable } from '@/components/ui/data-table'
import { useMarketplaceBlueprintResourcesTable } from '@/hooks/useMarketplaceBlueprintResourcesTable'
import { RoutePaths } from '@/routes/routes'

const DEFAULT_COLUMNS_TO_HIDE = {}

export const MarketplaceBlueprintResourcesList = () => {
  const { columns, data, isLoading } = useMarketplaceBlueprintResourcesTable()

  const navigate = useNavigate()

  return (
    <>
      <DataTable
        allowCreate
        columns={columns}
        data={data || []}
        defaultColumnVisibility={DEFAULT_COLUMNS_TO_HIDE}
        isLoading={isLoading}
        onRowClick={(row) => {
          navigate(
            `${RoutePaths.MARKETPLACE_BLUEPRINT_RESOURCES}/${row.original.id}`
          )
        }}
        tableConfigName="marketplace-blueprint-resources"
        toolbarProps={{
          exportButtonProps: {
            loading: isLoading
          }
        }}
      />
    </>
  )
}
