import { AppSandboxRequestsTableColumns } from '@/modules/apps/sandbox-requests/columns'
import { useQueryListAllSandboxRequests } from '@/services/sandbox.service'

export const useAppSandboxRequestsTable = (params: {
  limit: number
  offset: number
}) => {
  const { data, isFetching } = useQueryListAllSandboxRequests(params)

  return {
    data: data?.results?.map((sandbox_request) => sandbox_request) || [],
    page: data?.page,
    totalPages: data?.total_pages,
    columns: AppSandboxRequestsTableColumns,
    isLoading: isFetching
  }
}
