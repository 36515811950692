import { useMemo } from 'react'

import {
  useQueryGetAvailableBlueprints,
  useQueryGetInstalledBlueprints
} from '../services/blueprint.service'

import { InstalledBlueprintsColumns } from '@/modules/blueprint-patches/columns'

export const useInstalledBlueprintsTable = (organizationId?: string) => {
  const orgId = organizationId || ''

  const installedBlueprintsQuery = useQueryGetInstalledBlueprints(orgId)

  const availableBlueprintsQuery = useQueryGetAvailableBlueprints(orgId)

  const rowData = useMemo(() => {
    if (!installedBlueprintsQuery.data) {
      return []
    }

    return installedBlueprintsQuery.data.map((installedBlueprint) => {
      const availableBlueprint = availableBlueprintsQuery.data?.find(
        (blueprint) => blueprint.id === installedBlueprint.blueprint_id
      )

      return {
        ...installedBlueprint,
        blueprint_slug: availableBlueprint?.slug,
        blueprint_name: availableBlueprint?.name,
        blueprint_description: availableBlueprint?.description
      }
    })
  }, [installedBlueprintsQuery.data, availableBlueprintsQuery.data])

  return {
    data: rowData,
    isLoading: installedBlueprintsQuery.isLoading,
    columns: InstalledBlueprintsColumns
  }
}
